<template>
    <div id="content">
      <div class="container">
        <div class="info-block redeem-cashback">
          <header>
            <div class="logo">
              <img src="./../../../assets/images/logo.png" alt="BSH" />
            </div>
            <div class="brand-logos d-flex">
              <div class="logo">
                <img src="./../../../assets/images/bosch-logo.png" alt="Bosch" />
              </div>
              <div class="logo">
                <img src="./../../../assets/images/siemens-logo.png" alt="siemens" />
              </div>
              <div class="logo">
                <img src="./../../../assets/images/neff.png" alt="neff" />
              </div>
            </div>
          </header>
          <div class="row">
            <div class="col-md">
              <h1 class="theme-text text-center">Your Product</h1>    
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white py-4 px-3 px-md-4 comparison-info">
        <div class="greeting">
          <p class="text-primary-light mb-1">Dear Customer</p>
          <p>It was great to meet you today! Please see below the products we discussed below:</p>
        </div>
        <div class="product-retailers mb-5">

            <div class="product-wrapper">
              <div class="product border-bottom mb-4 pb-4">
                <div class="row align-items-center">
                  <div class="col-sm-auto mb-4 mb-sm-0">
                    <div class="slider product-slider mb-0 shadow-none">
                      <figure>
                        <img :src="
                          'https://media3.bosch-home.com/Product_Shots/600x337/' +
                          product.featured_image +
                          '.jpg'
                        " :alt="product.code" style="width: auto; height: auto" />
                      </figure>
                    </div>
                  </div>
                  <div class="col-sm">
                    <div class="details">
                      <h5 class="text-primary">
                        {{ product.brand.name }} {{ product.series }}
                        {{ product.code }} {{ product.name }}
                      </h5>
                      <div class="meta mb-0">
                        <a :href="'https://media3.bosch-home.com/Documents/specsheet/en-GB/' + product.code + '.pdf'">Please
                          click here for full specification</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row retailer-wrapper">
              <div v-for="retailer in retailers" :key="retailer.RetailerId" class="col-sm-6 mb-4">
                <div class="retailer row">
                  <div class="col-auto">
                    <div class="image mb-0 p-2 h-100 bg-transparent border rounded-0">
                      <img :src="retailer.RetailerLogoUrl" :alt="retailer.FriendlyName" />
                    </div>
                  </div>
                  <div class="col">
                    <div class="details">
                      <span class="price">{{ retailer.RetailerCurrencySymbol }}{{ retailer.Price }}</span>
                      <div class="in-stock" v-if="retailer.Availability == 'InStock'">
                        <i class="fa fa-check"></i> In Stock
                      </div>
                      <div class="in-stock not" v-else>
                        <i class="fa fa-times"></i> Out of Stock
                      </div>
  
                      <a :href="retailer.ClickThruUrl" class="btn btn-secondary-dark theme-bg" target="_blank">View
                        Retailer</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div class="mb-4">
          <p>Click the link below to find other Retailers in your area: <a
              href="https://www.bosch-home.co.uk/dealer-locator"
              class="font-weight-bold text-primary">https://www.bosch-home.co.uk/dealer-locator</a></p>
        </div>
        <div class="mb-4">
          <div class="box1 bg-primary-light text-white text-center rounded-lg">
            <h5 class="font-weight-bold mb-3">Was your appliance deemed unrepairable from one of our engineer visits?</h5>
            <h5 class="font-weight-bold mb-3">Receive a £50 Refund </h5>
            <p>Don’t forget, as a gesture of goodwill, if you choose to replace your appliance with one of our products
              within four weeks of the date of your engineer visit, we will refund £50 from the cost of the engineer or
              our service partner’s callout charge. </p>
            <p>To claim your cashback, please click <router-link to="/redeem"
                class="text-primary text-underline">HERE</router-link>
              T's & C's apply</p>
          </div>
        </div>
        <div class="mb-">
          <p>If you have any questions please call <a href="tel:03448928979" class="font-weight-bold text-primary">0344 892
              8979</a> and
            a member of our customer service team will assist you further with your query. </p>
          <p>Please note the following: </p>
          <ul class="pl-3">
            <li>Please be aware that this is only a selection of retailers that stocks the selected appliance and that
              other retailers are available for your consideration. All pricing and stock availability is correct at the
              time the email was sent.</li>
            <li>That you have checked the full specification of the appliance befor making the purchase, including the
              dimension, colour, installation type, spacing, plug type and voltage.</li>
            <li>We do not offer any connection services for the appliance so please speak to your retailer regarding
              installation options.</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  <script>
  /* global */

  import PermalinkService from "@/services/permalink.service";
  import RetailerService from "@/services/retailer.service";
  
  export default {
    name: "ProductPublicTemplate",
  
    data: function () {
      return {
        product: [],
        retailers: [],
      };
    },
    created() {
      this.getProduct();
    },
    watch: {},
    mounted() {},
  
    methods: {
      getProduct: function () {
        PermalinkService.get(this.$route.params.permalink).then((response) => {
          this.product = response.products[0];
          this.getRetailers();
        },
          (error) => {
            console.log(error)
          })
      },
      getRetailers() {
        let params = {
          key: '22441e0a-36ba-4254-8f52-752986e16b16',
          assetId: 1728,
          pid: this.product.code,
          csi_wtbsource: "engineer_url",
          csi_utm_campaign: this.$route.query.utm_campaign,
        }
        RetailerService.list(params).then((response) => {
          this.retailers = response[0]["RetailerProducts"];
        });
      },
    },
  };
  </script>
  <style scoped></style>
    